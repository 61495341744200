import { Users } from "types/Users";

import { apiSlice } from "./api";

const userSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query<Users, void>({
            query: () => ({
                url: "/admin/users",
            }),
            transformResponse: (res: Users) => {
                return res.map(user => {
                    if (user.trialStart)
                        user.trialStart = new Date(
                            (user.trialStart as unknown as number) * 1000,
                        );

                    if (user.trialEnd)
                        user.trialEnd = new Date(
                            (user.trialEnd as unknown as number) * 1000,
                        );

                    if (user.canceledAt)
                        user.canceledAt = new Date(
                            (user.canceledAt as unknown as number) * 1000,
                        );

                    return user;
                });
            },
        }),
        getUserToken: builder.query<string, string>({
            query: uid => ({
                url: `/admin/user/token`,
                params: { uid },
            }),
        }),
        deleteUser: builder.mutation<void, string>({
            query: uid => ({
                url: `/admin/user/delete`,
                params: { uid },
                method: "DELETE",
            }),
            onQueryStarted: async (uid, { dispatch, queryFulfilled }) => {
                const res = dispatch(
                    userSlice.util.updateQueryData(
                        "getUsers",
                        undefined,
                        (users: Users) => {
                            return users.filter(user => user.uid !== uid);
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch {
                    res.undo();
                }
            },
        }),
    }),
});

export const { useDeleteUserMutation, useGetUserTokenQuery, useGetUsersQuery } =
    userSlice;
