import React from 'react';
import { Dialog, DialogTitle, DialogContent, Stack, TextField, InputLabel, Select, MenuItem, IconButton, Divider } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface LinkDialogProps {
  open: boolean;
  onCancel: () => void;
  onInsert: (linkType: LinkTypeEnum, url: string, displayText: string | undefined) => void;
}

export enum LinkTypeEnum {
    WebpageNewWindow = 'Webpage',
    WebpageSameWindow = 'Webpage-self',
    Email = 'Email',
}

const linkTypeText = {
    [LinkTypeEnum.WebpageNewWindow]: 'Webpage - opens in a new window',
    [LinkTypeEnum.WebpageSameWindow]: 'Webpage - opens in the same window',
    [LinkTypeEnum.Email]: 'Email - opens in default email editor',
};

const InsertLinkDialog: React.FC<LinkDialogProps> = ({ open, onInsert, onCancel }) => {
const [linkType, setLinkType] = React.useState(LinkTypeEnum.WebpageNewWindow);
const [displayText, setDisplayText] = React.useState('');
const [url, setUrl] = React.useState('');

//Error states
const [urlError, setUrlError] = React.useState({error: false, message: ''});

const handleInsert = () => {
    //if the linktype is email, validate the email address
    if (linkType === LinkTypeEnum.Email) {
        if (!url.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setUrlError({error: true, message: 'Invalid email address'});
            return;
        }
    }
    //if the linktype is webpage, validate the url to make sure it is a webpage
    else if (linkType === LinkTypeEnum.WebpageNewWindow || linkType === LinkTypeEnum.WebpageSameWindow) {
        if (!url.match(/^(http|https):\/\/[^ "]+$/)) {
            setUrlError({error: true, message: 'Invalid URL'});
            return;
        }
    }
    onInsert(linkType, url, displayText);
};

return (
    <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>
            Insert Link
        </DialogTitle>
        <DialogContent>
        <Stack direction="column" spacing={1}>
        <TextField value={url} error={urlError.error} helperText={urlError.message}
            onChange={(e) => setUrl(e.target.value)} label="URL or email address" fullWidth />
        <TextField value={displayText} onChange={(e) => setDisplayText(e.target.value)} label="Display Text - leave blank to show raw url" fullWidth />
        <InputLabel id="link-type">Link Type:</InputLabel>
        <Select
            labelId="link-type"
            value={linkType}
            onChange={(e) => setLinkType(e.target.value as LinkTypeEnum)}
            fullWidth
        >
            {Object.entries(linkTypeText).map(([key, value]) => (
                <MenuItem key={key} value={key}>{value}</MenuItem>
            ))}
        </Select>
        </Stack>
        </DialogContent>
        <Stack direction="row" spacing={1}>
            <IconButton color="error" onClick={onCancel}>
                <CancelIcon />
                Cancel
            </IconButton>
            <IconButton color="success" onClick={handleInsert}>
                <CheckCircleIcon />
                Insert
            </IconButton>
        </Stack>
    </Dialog>
);
}

export default InsertLinkDialog;