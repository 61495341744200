import { ThemeProvider } from "@emotion/react";
import { CircularProgress, createTheme, CssBaseline } from "@mui/material";
import PrivateRoute from "components/auth/PrivateRoute";
import useAuthState from "hooks/firebase/useAuthState";
import EditableContentBlock from "pages/EditableContentBlock/EditableContentBlock";
import Email from "pages/Email";
import EmailSender from "pages/EmailSender";
import Home from "pages/Home";
import Login from "pages/Login";
import Logs from "pages/Logs";
import OrganisationList from "pages/Organisations";
import Spoof from "pages/Spoof";
import Users from "pages/Users";
import { createContext, useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router";

function App() {
    const [_, loading] = useAuthState();

    if (loading) return <CircularProgress />;

    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route
                path='/'
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route
                path='/users'
                element={
                    <PrivateRoute>
                        <Users />
                    </PrivateRoute>
                }
            />
            <Route
                path='/organisations'
                element={
                    <PrivateRoute>
                        <OrganisationList />
                    </PrivateRoute>
                }
            />
            <Route
                path='/spoof'
                element={
                    <PrivateRoute>
                        <Spoof />
                    </PrivateRoute>
                }
            />
            <Route
                path='/logs'
                element={
                    <PrivateRoute>
                        <Logs />
                    </PrivateRoute>
                }
            />
            <Route
                path='/emails'
                element={
                    <PrivateRoute>
                        <Email />
                    </PrivateRoute>
                }
            />
            <Route
                path='/email-sender'
                element={
                    <PrivateRoute>
                        <EmailSender />
                    </PrivateRoute>
                }
            />
            <Route
                path='EditableContentBlock/:contentId'
                element={
                    <PrivateRoute>
                        <EditableContentBlock />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export const ColorModeContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleColorMode: () => {},
    mode: "" as "light" | "dark",
});

const get_initial_mode = () => {
    if (localStorage.getItem("mode")) {
        return localStorage.getItem("mode") as "light" | "dark";
    } else {
        return window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light";
    }
};

export default function ToggleColorMode() {
    const [mode, setMode] = useState<"light" | "dark">(get_initial_mode());
    const colorMode = useMemo(
        () => () => {
            setMode(prevMode => (prevMode === "light" ? "dark" : "light"));
        },
        [],
    );

    useEffect(() => {
        localStorage.setItem("mode", mode);
    }, [mode]);

    const theme = useMemo(
        () =>
            mode === "light"
                ? createTheme({
                      palette: {
                          mode,
                          primary: {
                              main: "#ffe0e9",
                          },
                          secondary: {
                              main: "#0c0341",
                          },
                      },
                      components: {
                          MuiButton: {
                              styleOverrides: {
                                  text: {
                                      color: "rgb(12, 3, 65)",
                                      ":hover": {
                                          backgroundColor:
                                              "rgba(12, 3, 65, 0.08)",
                                      },
                                  },
                              },
                          },
                      },
                  })
                : createTheme({
                      palette: {
                          mode,
                          primary: {
                              main: "#ffe0e9",
                          },
                          secondary: {
                              main: "#0c0341",
                          },
                      },
                  }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={{ toggleColorMode: colorMode, mode }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
