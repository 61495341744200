import {
    Box,
    Container,
    Paper,
    Stack,
    Typography,
    styled,
} from "@mui/material";
import Footer from "components/page_parts/Footer";
import Header from "components/page_parts/Header";
import { useMemo } from "react";
import { useGetUsersQuery } from "redux/user";
import { SubscriptionStatus } from "types/Users";

const Home = () => {
    const { data: users, isFetching, isError, error } = useGetUsersQuery();

    const trialingUsers = useMemo(
        () =>
            users?.filter(
                p => p.subscriptionStatus === SubscriptionStatus.trialing,
            ).length,
        [users],
    );

    const activeUsers = useMemo(
        () =>
            users?.filter(
                p => p.subscriptionStatus === SubscriptionStatus.active,
            ).length,
        [users],
    );

    const canceledUsers = useMemo(
        () =>
            users?.filter(
                p => p.subscriptionStatus === SubscriptionStatus.cancelled,
            ).length,
        [users],
    );

    return (
        <>
            <Header />
            <Container>
                <Stack margin={3} justifyContent='center' direction='row' spacing={2}>
                    <Paper square={false}>
                        <Stack
                            padding={3}
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}>
                            <Typography variant='h2'>All Users</Typography>
                            <Typography variant='h3'>
                                {users?.length}
                            </Typography>
                        </Stack>
                    </Paper>
                    <Paper square={false}>
                        <Stack
                            padding={3}
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}>
                            <Typography variant='h2'>Active</Typography>
                            <Typography variant='h3'>{activeUsers}</Typography>
                        </Stack>
                    </Paper>
                    <Paper square={false}>
                        <Stack
                            padding={3}
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}>
                            <Typography variant='h2'>Trialing</Typography>
                            <Typography variant='h3'>
                                {trialingUsers}
                            </Typography>
                        </Stack>
                    </Paper>
                    <Paper square={false}>
                        <Stack
                            padding={3}
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}>
                            <Typography variant='h2'>Canceled</Typography>
                            <Typography variant='h3'>
                                {canceledUsers}
                            </Typography>
                        </Stack>
                    </Paper>
                </Stack>
            </Container>
            <Footer />
        </>
    );
};

export default Home;

const Stats = styled("div")`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

const Stat = styled("div")`
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 200px;
    height: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
