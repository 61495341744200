import useAuthState from "hooks/firebase/useAuthState";
import { useEffect, useState } from "react";
import { auth } from "utils/firebase";

const useIsAdmin = () => {
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [pending, setPending] = useState(true);
    const [user, loading] = useAuthState();

    useEffect(() => {
        if (!loading && !user) {
            setIsAuthorised(false);
            setPending(false);
            return;
        }
        auth.currentUser?.getIdTokenResult().then(t => {
            setIsAuthorised(!!t.claims.admin);
            setPending(false);
        });
    }, [user, loading]);

    return [isAuthorised, pending];
};

export default useIsAdmin;
