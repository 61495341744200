export const ValidDateFormats = [
    'MMMM YYYY',
    'MMM YYYY',
    'MMMM',
];

export enum DateTag {
    LATEST_MONTH = 1,
    PREVIOUS_MONTH = 2,
    SAME_MONTH_LAST_YEAR = 3,
}

export const DateTagToText: Record<DateTag, string> = {
    [DateTag.LATEST_MONTH]: 'Latest',
    [DateTag.PREVIOUS_MONTH]: 'Previous',
    [DateTag.SAME_MONTH_LAST_YEAR]: 'Same month last year',
};