import { apiSlice } from "./api";

const editableContentBlockSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContent: builder.query<
            { content: string; title?: string } | undefined,
            string
        >({
            query: contentId => ({
                url: `/editable-content-blocks/content`,
                params: { contentId },
                method: "get",
            }),
        }),
        updateEditableContentBlock: builder.mutation<
            void,
            { contentId: string; content: string; title?: string }
        >({
            query: content => ({
                url: `/editable-content-blocks`,
                method: "put",
                data: content,
            }),
        }),
    }),
});

export const { useGetContentQuery, useUpdateEditableContentBlockMutation } =
    editableContentBlockSlice;
