import "firebase/compat/auth";
import { Stack, styled } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "utils/firebase";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { ReactComponent as ErrorSvg } from "assets/auth/error.svg";
import { ReactComponent as Password } from "assets/auth/password.svg";
import { ReactComponent as Person } from "assets/auth/person.svg";
import { handleSignIn, signInWithGoogle } from "./helpers/functions";
import useTitle from "hooks/useTitle";

export const EmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

function LoginAuth() {
    const navigate = useNavigate();
    const {
        state: { from },
    } = useLocation();

    useTitle("Login");

    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>(
        undefined,
    );
    const [error, setError] = useState<string | undefined>(undefined);

    return (
        <Stack
            bgcolor='white'
            padding={4}
            maxWidth={400}
            borderRadius={10}
            margin='auto'
            alignItems='center'>
            <LoginHeader>Login</LoginHeader>
            {error && (
                <CustomError>
                    <ErrorSvg />
                    {error}
                </CustomError>
            )}

            <LoginContainer
                onSubmit={(e: any) => {
                    e.preventDefault();
                    const email = e.target[0].value as string;
                    const password = e.target[1].value as string;

                    if (email.toLowerCase().match(EmailRegex)) {
                        signInWithEmailAndPassword(auth, email, password)
                            .then(res => {
                                handleSignIn(res, from, navigate);
                            })
                            .catch(err => {
                                switch (err.code) {
                                    case "auth/invalid-email":
                                        setEmailError("Invalid email address");
                                        break;
                                    case "auth/user-disabled":
                                        setError(
                                            "The user account has been disabled by an administrator.",
                                        );
                                        break;
                                    case "auth/wrong-password":
                                        setPasswordError(
                                            "The password is invalid",
                                        );
                                        break;

                                    case "auth/user-not-found":
                                        setError(
                                            "There is no user record corresponding to this identifier. The user may not have been created yet.",
                                        );
                                        break;
                                    default:
                                        console.log("err", err.code);
                                }
                            });
                    } else {
                        setEmailError("Invalid email address");
                    }
                }}>
                <InputContainer>
                    <span>Email</span>
                    <InputDiv>
                        <PersonSVG />
                        <input
                            type='text'
                            name='email'
                            placeholder='Type your email'
                        />
                        {emailError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{emailError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>

                <InputContainer>
                    <span>Password</span>
                    <InputDiv>
                        <PasswordSvg />
                        <input
                            type='password'
                            name='pass'
                            placeholder='Type your password'
                        />
                        {passwordError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{passwordError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>

                <ForgotPassword onClick={() => navigate("forgot-password")}>
                    Forgot password?
                </ForgotPassword>

                <LoginButton type='submit'>
                    <div />
                    <p>Login</p>
                </LoginButton>

                <ExternalLogin>
                    <span>Or Sign In Using</span>
                    <div>
                        <GoogleButton
                            onClick={() =>
                                signInWithGoogle(navigate, from)
                            }></GoogleButton>
                    </div>
                </ExternalLogin>

                <SignUP>
                    <span>Or Sign Up Using</span>

                    <a href='#'>Sign Up</a>
                </SignUP>
            </LoginContainer>
        </Stack>
    );
}

export default LoginAuth;

const InputError = styled("div")`
    position: absolute;
    right: 0;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        fill: #f02929;
        cursor: pointer;
    }

    div {
        display: none;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: calc(5px + 100%);
        background: rgb(248, 208, 200);
        padding: 8px;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent rgb(248, 208, 200) transparent transparent;
        }
    }

    &:hover {
        div {
            display: block;
        }
    }
`;

const PersonSVG = styled(Person)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;
const PasswordSvg = styled(Password)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;

const ExternalSignIn = styled("div")`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const GoogleButton = styled(ExternalSignIn)`
    background-color: ${props => props.theme.palette.primary.main};

    background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    border: 2px solid ${props => props.theme.palette.primary.main};
    color: #fff;
    font-size: 35px;
    font-weight: 700;

    &:hover {
        border: 2px solid ${props => props.theme.palette.secondary.main};
        /* background: #e91e1e; */
    }
`;

const SignUP = styled("div")``;

const CustomError = styled("div")`
    border-radius: 5px;
    padding: 10px;
    background: rgb(248, 208, 200);
    display: flex;
    align-items: center;
    margin-top: 15px;

    svg {
        height: 70px;
        width: 70px;
        margin-right: 10px;
    }
`;

const ForgotPassword = styled("div")`
    height: 30px;
    margin: auto 0;

    color: #333;
    line-height: 1.2;
    display: block;
    width: 100%;
    background: 0 0;
    text-align: right;
    font-size: 16px;
`;

const LoginButton = styled("button")`
    all: unset;
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    width: 50%;
    margin: 10px auto;
    padding: 10px 0;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;

    div {
        transition: 0.25s;
        position: absolute;
        width: 300%;
        height: 100%;
        background-color: ${props => props.theme.palette.primary.main};

        &:hover {
            transform: translateX(-30%);
        }
    }

    p {
        z-index: 1;
        margin: 0;
    }
`;

const InputDiv = styled("div")`
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 2px solid #ccc;
    position: relative;

    input {
        padding-left: 10px;
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        height: 30px;
        background: 0 0;
        outline: none;
        border: none;
        padding: 10px 30px;
        position: relative;
        background-color: transparent;
    }
`;
const InputContainer = styled("div")`
    span {
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        background: 0 0;
    }
`;

const ExternalLogin = styled("div")`
    font-size: 16px;
    color: #333;
    line-height: 1.2;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        margin-top: 10px;
    }
`;

const LoginContainer = styled("form")`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
`;

const LoginHeader = styled("h1")`
    margin: 0;
`;

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 40px;
    width: 100%;
    max-width: 400px;
`;
