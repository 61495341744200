import { DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import s3Client from "./s3Client";

const uploadImageToAws: (file: File, fileName: string) => Promise<string | undefined> =
    async (file, fileName) => {
        //Check the file is an image
        if (!file.type.includes('image')) {
            alert('Please upload an image file');
            return;
        }

        const saveFileName = `${fileName}.${file.name.split('.').pop()}`;

        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: `img/${saveFileName}`,
        };
        try {
            const fileContents = await getFileBytes(file);

            //Check the file size is less than 1MB
            if (fileContents.byteLength > 1048576) {
                alert('Please resize the image to be smaller than 1MB.');
                return;
            }

            const response = await s3Client.send(new PutObjectCommand({
                ...params,
                Body: fileContents,
            }));

            return saveFileName;
        } catch (err) {
            console.error(err);
        }
    }

    const deleteFileFromAws = async (fileName: string) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: `img/${fileName}`,
        };
        try {
            const response = await s3Client.send(new DeleteObjectCommand(params));
        } catch (err) {
            console.error(err);
        }
    }

    export default {uploadImageToAws, deleteFileFromAws};

    const getFileBytes = async (file: File) => {
        return new Promise<Uint8Array>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.result instanceof ArrayBuffer) {
                    resolve(new Uint8Array(reader.result));
                } else {
                    reject(new Error('Could not read file'));
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }
