export enum OrgSubscriptionStatus {
    none = 0,
    xeroAppStore = 1,
    manual = 2,
    stripe = 3,
}

export enum OrgNotificationOptions {
    none = 0,
    emailMonthlyUpdate = 1,
}

export enum OrganisationTypes {
    transactional = 0,
    subscription = 1,
}
export enum BudgetSources {
    xero = 0,
    hellocashflow = 1,
}
export enum SummaryDataType {
    'ProfitLoss' = 1,
    'ProviderBudget' = 2, //Budget downloaded from provider
    'Cashflow' = 3,
    'BalanceSheet' = 4,
    'HelloCashflowBudget' = 5,
    'AggragatedData' = 6,
}
