import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import { Logs } from "types/Logs";
import { Users } from "types/Users";

interface BaseQueryProps {
    baseUrl: string;
}

const axiosBaseQuery =
    ({
        baseUrl,
    }: BaseQueryProps): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig["method"];
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params }) => {
        const token = await getAuth().currentUser?.getIdToken();
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: axiosBaseQuery({
        // baseUrl: "http://localhost:8081",
        baseUrl: process.env.REACT_APP_API_URL as string,
    }),

    endpoints: builder => ({
        getLogs: builder.query<Logs, number | void>({
            query: pageSize => ({
                url: `/admin/logs`,
                params: { pageSize },
            }),
            transformResponse: (res: Logs) => {
                return res.map(log => {
                    log.timestamp = new Date(
                        log.timestamp as unknown as string,
                    );
                    return log;
                });
            },
        }),
    }),
});

export const { useGetLogsQuery } = apiSlice;
