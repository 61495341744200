import { S3Client } from "@aws-sdk/client-s3";

// Helper function that creates an Amazon S3 service client module.
const createS3Client = () => {
    const s3Client = new S3Client({
        region: process.env.REACT_APP_AWS_REGION!,
        credentials:{
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
        }
    });
    return s3Client;
}

const s3Client = createS3Client();
export default s3Client;