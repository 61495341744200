import { UserInfo } from "firebase/auth";

export type Users = AllUser[];

export interface AllUser extends FirebaseUser {
    customerStripeLink: string;
    subscriptionLink?: string;
    subscriptionStatus: SubscriptionStatus;
    trialEnd?: Date;
    trialStart?: Date;
    canceledAt?: Date;
    subscriptionStartDate?: string;
}

export enum SubscriptionStatus {
    active = "active",
    cancelled = "canceled",
    incomplete = "incomplete",
    incomplete_expired = "incomplete_expired",
    past_due = "past_due",
    paused = "paused",
    trialing = "trialing",
    unpaid = "unpaid",
    inactive = "inactive",
}

export interface FirebaseUser {
    uid: string;
    displayName?: string;
    photoURL?: string;
    phoneNumber?: string;
    disabled: boolean;
    email?: string;
    createdAt: string;
    emailVerified: boolean;
    signedInAt: string;
    provider: UserInfo[];
    customClaims?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
}
