import {
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { ColorModeContext } from "App";
import Footer from "components/page_parts/Footer";
import Header from "components/page_parts/Header";
import { useContext, useEffect, useRef, useState } from "react";
import EmailEditor, { EditorRef } from "react-email-editor";
import {
    MergeField,
    useCreateTemplateMutation,
    useDeleteTemplateMutation,
    useGetTemplatesQuery,
    useUpdateTemplateMutation,
} from "redux/email";

interface BubbleStyle {
    size: number;
    backgroundColor: string;
    color: string;
    padding: number;
    bigFont: number;
    smallFont: number;
    borderColour: string;
    text1: string;
    text2: string;
    text3: string;
}

const defaultBubbleStyle: BubbleStyle = {
    size: 200,
    backgroundColor: "#0c0341",
    color: "white",
    padding: 45,
    bigFont: 24,
    smallFont: 12,
    borderColour: "#0c0341",
    text1: "Here's your revenue for last month:",
    text2: "$834",
    text3: "Nov 23",
};

const Email = () => {
    const { mode: colorMode } = useContext(ColorModeContext);
    const { data: templates } = useGetTemplatesQuery();
    const [createTemplate] = useCreateTemplateMutation();
    const [updateTemplate] = useUpdateTemplateMutation();
    const [removeTemplate] = useDeleteTemplateMutation();
    const [editorReady, setEditorReady] = useState(false);
    const [subject, setSubject] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [deleteTemplate, setDeleteTemplate] = useState<number | undefined>(
        undefined,
    );
    const [importTemplate, setImport] = useState(false);
    const [createBubble, setBubble] = useState(false);
    const [bubbleStyle, setBubbleStyle] =
        useState<BubbleStyle>(defaultBubbleStyle);
    const [duplicateTemplate, setDuplicate] = useState(false);
    const [mergeTags, setMergeTags] = useState<MergeField[]>([]);
    const emailEditorRef = useRef<EditorRef>(null);

    const [newMergeTag, setNewMergeTage] = useState<boolean>(false);

    // useEffect(() => {
    //     tools.map(tool => {
    //         emailEditorRef.current?.editor?.registerTool({});
    //     });
    // }, [editorReady]);

    useEffect(() => {
        if (emailEditorRef.current && emailEditorRef.current.editor) {
            emailEditorRef.current.editor.setMergeTags(
                mergeTags.reduce((acc, tag) => {
                    acc[tag.id] = {
                        name: tag.name,
                        value: tag.value,
                        sample: tag.sample,
                    };
                    return acc;
                }, {} as any),
            );
        }
    }, [mergeTags, editorReady]);

    const clear = () => {
        setName("");
        setSubject("");
        setMergeTags([]);
        emailEditorRef.current?.editor?.loadBlank();
    };

    const exportHtml = () => {
        if (emailEditorRef.current && emailEditorRef.current.editor) {
            if (name && subject) {
                emailEditorRef.current.editor.exportHtml(data => {
                    const { design, html } = data;
                    if (templates?.some(t => t.name === name)) {
                        updateTemplate({
                            name,
                            subject,
                            template: design,
                            html,
                            mergeFields: mergeTags,
                        });
                    } else
                        createTemplate({
                            name,
                            subject,
                            template: design,
                            html,
                            mergeFields: mergeTags,
                        });
                });
            } else {
                alert("Please enter name and subject");
            }
        }
    };

    const setTemplate = (index: number) => {
        const template = templates?.[index];
        if (
            template &&
            emailEditorRef.current &&
            emailEditorRef.current.editor
        ) {
            setName(template.name);
            setSubject(template.subject);
            setMergeTags(template.mergeFields);
            emailEditorRef.current.editor.loadDesign(template.template as any);
        }
    };

    const code = `
            <div
            style="margin: auto; display: block; width: 200px; height: 200px; background-color: ${bubbleStyle.backgroundColor}; color: ${bubbleStyle.color}; border-radius: 50%; border: 1px solid ${bubbleStyle.borderColour}; padding: ${bubbleStyle.padding}px;">
                <span style="width: 100%; display: block;  text-align: center; display: block; font-size: ${bubbleStyle.smallFont}px;">${bubbleStyle.text1}</span>
                <span style="width: 100%; display: block;  text-align: center; font-size: ${bubbleStyle.bigFont}px;; ">${bubbleStyle.text2}</span>
                <span style="width: 100%; text-align: center; display: block; font-size: ${bubbleStyle.smallFont}px; ">
                ${bubbleStyle.text3}
                </span>
        </div>
    `;

    return (
        <>
            <Header />
            <Container>
                <Dialog
                    open={deleteTemplate !== undefined}
                    onClose={() => setDeleteTemplate(undefined)}>
                    <DialogTitle>Delete Template?</DialogTitle>
                    <DialogContent>
                        Do you really want to delete{" "}
                        {templates?.[deleteTemplate || 0]?.name} template?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteTemplate(undefined)}
                            color='error'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                const index = deleteTemplate as number;
                                const templateName = templates?.[index]
                                    ?.name as string;
                                removeTemplate(templateName);
                                if (name === templateName) clear();
                                setDeleteTemplate(undefined);
                            }}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={duplicateTemplate}
                    onClose={() => setDuplicate(false)}
                    PaperProps={{
                        component: "form",
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(
                                (formData as any).entries(),
                            );
                            const { name: newName, subject } = formJson;

                            const template = templates?.find(t => {
                                return t.name === name;
                            });

                            if (!template) {
                                alert("No template found");
                                return;
                            }

                            createTemplate({
                                name: newName,
                                subject,
                                template: template.template,
                                html: template.html,
                                mergeFields: template.mergeFields,
                            });
                        },
                    }}>
                    <DialogTitle>Duplicate Template</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Set the name and subject of the new template.
                        </DialogContentText>
                        <Stack spacing={2}>
                            <TextField
                                id='name'
                                label='Name'
                                name='name'
                                required
                                autoFocus
                                variant='outlined'
                            />
                            <TextField
                                id='subject'
                                label='Subject'
                                name='subject'
                                required
                                variant='outlined'
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDuplicate(false)}
                            color='error'>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            onClick={() => {
                                setDuplicate(false);
                            }}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={importTemplate}
                    onClose={() => setImport(false)}
                    PaperProps={{
                        component: "form",
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(
                                (formData as any).entries(),
                            );
                        },
                    }}>
                    <DialogTitle>Import Template</DialogTitle>
                    <DialogContent></DialogContent>
                    <DialogActions>
                        <Button onClick={() => setImport(false)} color='error'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setImport(false);
                            }}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth='lg'
                    open={createBubble}
                    onClose={() => setBubble(false)}>
                    <DialogTitle>Create Bubble</DialogTitle>
                    <DialogContent>
                        <Stack direction={"row"} spacing={2} padding={2}>
                            <Stack spacing={2}>
                                {Object.entries(bubbleStyle).map(
                                    ([key, value]) => {
                                        return (
                                            <TextField
                                                key={key}
                                                id={key}
                                                label={key}
                                                name={key}
                                                value={value}
                                                onChange={e => {
                                                    setBubbleStyle({
                                                        ...bubbleStyle,
                                                        [key]: e.target.value,
                                                    });
                                                }}
                                            />
                                        );
                                    },
                                )}
                            </Stack>
                            <Box>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: code,
                                    }}></div>
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setBubble(false)} color='error'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(code);
                                setBubble(false);
                            }}>
                            Copy
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={newMergeTag}
                    onClose={() => setNewMergeTage(false)}
                    PaperProps={{
                        component: "form",
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(
                                (formData as any).entries(),
                            );

                            const mergeTag: MergeField = {
                                name: formJson.name,
                                id: formJson.id,
                                sample: formJson.sample,
                                value: `{{${formJson.id}}}`,
                            };

                            setMergeTags(p => [...p, mergeTag]);
                            setNewMergeTage(false);
                        },
                    }}>
                    <DialogTitle>Create new merge tag</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Merge tags are used to personalize emails. For
                            example, you can use merge tags to include the
                            recipient's first name in your email.
                        </DialogContentText>
                        <Stack spacing={2}>
                            <TextField
                                id='name'
                                label='Name'
                                name='name'
                                required
                                autoFocus
                                variant='outlined'
                            />
                            <TextField
                                id='id'
                                label='ID'
                                name='id'
                                placeholder="e.g. 'first_name'"
                                required
                                variant='outlined'
                            />
                            <TextField
                                id='sample'
                                label='Sample'
                                name='sample'
                                required
                                variant='outlined'
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setNewMergeTage(false)}
                            color='error'>
                            Cancel
                        </Button>
                        <Button type='submit'>Submit</Button>
                    </DialogActions>
                </Dialog>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    padding={2}>
                    <Stack>
                        <Stack direction='row' spacing={1}>
                            <Button variant='contained' onClick={exportHtml}>
                                Save
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => setDuplicate(true)}>
                                Duplicate
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => setImport(true)}>
                                Import
                            </Button>
                            <Button variant='contained' onClick={clear}>
                                New
                            </Button>
                            <Button variant='contained' onClick={() => setBubble(true)}>
                                Add Bubble
                            </Button>
                        </Stack>
                        <Stack margin={2} width={300} spacing={1}>
                            <TextField
                                label='Name'
                                variant='outlined'
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                            <TextField
                                label='Subject'
                                variant='outlined'
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                            />
                        </Stack>
                    </Stack>
                    <Stack alignItems='center'>
                        <Typography variant='h6'>Templates</Typography>
                        <Stack flexWrap='wrap' margin={2} spacing={1}>
                            {templates?.map((template, index) => (
                                <Chip
                                    sx={{ minWidth: 100 }}
                                    key={index}
                                    label={template.name}
                                    onClick={() => setTemplate(index)}
                                    onDelete={() => setDeleteTemplate(index)}
                                />
                            ))}
                        </Stack>
                    </Stack>
                    <Stack>
                        <Stack direction='row' spacing={2}>
                            <Typography variant='h6'>Merge Tags</Typography>
                            <Button
                                onClick={() => setNewMergeTage(true)}
                                variant='contained'>
                                <Typography variant='body2'>
                                    Add Merge Tag
                                </Typography>
                            </Button>
                        </Stack>
                        <Stack margin={2} spacing={1}>
                            {mergeTags.map((tag, index) => (
                                <Chip
                                    sx={{ minWidth: 100 }}
                                    key={index}
                                    label={tag.name}
                                    onClick={() => {
                                        navigator.clipboard.writeText(tag.value);
                                    }}
                                    onDelete={() =>
                                        setMergeTags(
                                            mergeTags.filter(
                                                (tag, i) => i !== index,
                                            ),
                                        )
                                    }
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>

                <EmailEditor
                    minHeight={"80vh"}
                    options={{
                        displayMode: "email",
                        features: {
                            stockImages: true,
                        },
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        appearance: {
                            theme: colorMode,
                        },
                        projectId: 204655,
                    }}
                    onReady={test => {
                        // test.registerTool
                        setEditorReady(true);
                    }}
                    ref={emailEditorRef}
                />
            </Container>
            <Footer />
        </>
    );
};

export default Email;
