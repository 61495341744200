export enum DataTagType {
    REVENUE_LATEST_MONTH = "REVENUE_LATEST_MONTH",
    REVENUE_PREVIOUS_MONTH = "REVENUE_PREVIOUS_MONTH",
    REVENUE_SAME_MONTH_LAST_YEAR = "REVENUE_SAME_MONTH_LAST_YEAR",
    EXPENSES_LATEST_MONTH = "EXPENSES_LATEST_MONTH",
    EXPENSES_PREVIOUS_MONTH = "EXPENSES_PREVIOUS_MONTH",
    EXPENSES_SAME_MONTH_LAST_YEAR = "EXPENSES_SAME_MONTH_LAST_YEAR",
    PROFIT_LATEST_MONTH = "PROFIT_LATEST_MONTH",
    PROFIT_PREVIOUS_MONTH = "PROFIT_PREVIOUS_MONTH",
    PROFIT_SAME_MONTH_LAST_YEAR = "PROFIT_SAME_MONTH_LAST_YEAR",
    DIRECT_COSTS_LATEST_MONTH = "DIRECT_COSTS_LATEST_MONTH",
    DIRECT_COSTS_PREVIOUS_MONTH = "DIRECT_COSTS_PREVIOUS_MONTH",
    //Budget numbers
    REVENUE_BUDGET_LATEST_MONTH = "REVENUE_BUDGET_LATEST_MONTH",
    REVENUE_BUDGET_PREVIOUS_MONTH = "REVENUE_BUDGET_PREVIOUS_MONTH",
    REVENUE_BUDGET_SAME_MONTH_LAST_YEAR = "REVENUE_BUDGET_SAME_MONTH_LAST_YEAR",
    EXPENSES_BUDGET_LATEST_MONTH = "EXPENSES_BUDGET_LATEST_MONTH",
    EXPENSES_BUDGET_PREVIOUS_MONTH = "EXPENSES_BUDGET_PREVIOUS_MONTH",
    EXPENSES_BUDGET_SAME_MONTH_LAST_YEAR = "EXPENSES_BUDGET_SAME_MONTH_LAST_YEAR",
    PROFIT_BUDGET_LATEST_MONTH = "PROFIT_BUDGET_LATEST_MONTH",
    PROFIT_BUDGET_PREVIOUS_MONTH = "PROFIT_BUDGET_PREVIOUS_MONTH",
    PROFIT_BUDGET_SAME_MONTH_LAST_YEAR = "PROFIT_BUDGET_SAME_MONTH_LAST_YEAR",
}
