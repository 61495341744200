import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Container,
    Dialog,
    DialogActions,
    MenuItem,
    Stack,
    styled,
    TextField,
} from "@mui/material";
import Header from "components/page_parts/Header";
import FlatList, { FlatListProps } from "flatlist-react/lib";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useGetOrganisationsQuery } from "redux/organisation";
import { useAppDispatch } from "redux/store";
import { useDeleteUserMutation, useGetUsersQuery } from "redux/user";
import { setUID } from "redux/userSlice";
import { AllUser, SubscriptionStatus } from "types/Users";

const SELECT_OPTIONS = [
    "default",
    "displayName",
    "displayNameDesc",
    "email",
    "emailDesc",
];

const FILTER_OPTIONS = ["all", "trialing", "inactive", "active", "cancelled"];

const handleSort = (value: string) => {
    switch (value) {
        case "default":
            return undefined;
            break;
        case "displayName":
            return [{ key: "displayName" }];
            break;
        case "displayNameDesc":
            return [{ key: "displayName", descending: true }];
            break;
        case "email":
            return [{ key: "email" }];
            break;
        case "emailDesc":
            return [{ key: "email", descending: true }];
            break;
    }
};

const Users = () => {
    const navaigate = useNavigate();
    const dispatch = useAppDispatch();
    const [delteUserMutation, result] = useDeleteUserMutation();
    const [open, setOpen] = useState(false);
    const { data: users, isFetching, isError, error } = useGetUsersQuery();
    const [selectedSort, setSelectedSort] = useState<string>("default");
    // const { Modal, setOpen, setClose } = useModal();
    const [deleteUser, setDeleteUser] = useState<AllUser>();
    const [selectedFilter, setSelectedFilter] = useState<string>("all");
    const [currentSearch, setCurrentSearch] = useState("");

    const { data: organisations, isLoading } = useGetOrganisationsQuery();
    const userOrgs: Record<string, string> = {};
    if (organisations && users) {
        organisations.forEach(org => {
            org.users.forEach(user => {
                userOrgs[user.firebaseId] = userOrgs[user.firebaseId]
                    ? userOrgs[user.firebaseId] + ", " + org.name
                    : org.name;
            });
        });
    }

    const spoofUser = (uid: string) => {
        dispatch(setUID(uid));
        navaigate("/spoof");
    };

    const filter: FlatListProps<AllUser>["filterBy"] = (() => {
        switch (selectedFilter) {
            case "all":
                return undefined;
                break;
            case "trialing":
                return (user, _) => {
                    return (
                        user.subscriptionStatus === SubscriptionStatus.trialing
                    );
                };
                break;
            case "inactive":
                return (user, _) => {
                    return (
                        user.subscriptionStatus === SubscriptionStatus.inactive
                    );
                };
                break;
            case "active":
                return (user, _) => {
                    return (
                        user.subscriptionStatus === SubscriptionStatus.active
                    );
                };
                break;
            case "cancelled":
                return (user, _) => {
                    return (
                        user.subscriptionStatus === SubscriptionStatus.cancelled
                    );
                };
                break;
            default:
                break;
        }
    })();

    const viewUser = (user: AllUser) => {
        return (
            <Accordion key={user.uid}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <span>
                            <Avatar
                                alt={user.displayName}
                                src={user.photoURL}
                            />
                        </span>

                        <span>{user.subscriptionStatus}</span>

                        <span>{user.displayName}</span>
                        <span>
                            {user.email}{" "}
                            <input
                                type='checkbox'
                                readOnly
                                checked={user.emailVerified}
                            />
                        </span>
                    </Stack>
                </AccordionSummary>

                <AccordionDetails>
                    <UserDetails>
                        <span>
                            <b>User uid</b>: {user.uid}
                        </span>
                        <div>
                            <span>
                                <b>Last Loggin in at</b>: {user.signedInAt}
                            </span>{" "}
                            <span>
                                <b>Created at</b>: {user.createdAt}
                            </span>
                        </div>
                        {user.phoneNumber && (
                            <span>
                                <b>phone number</b>: {user.phoneNumber}
                            </span>
                        )}
                        {user.subscriptionStatus ===
                            SubscriptionStatus.trialing && (
                            <span>
                                <b>trial</b>: {user.trialStart?.toDateString()}{" "}
                                - {user.trialEnd?.toDateString()}
                            </span>
                        )}
                        <span>
                            <b>Providers</b>:{" "}
                            {user.provider.map(p => p.providerId + " ")}
                        </span>
                        {user.customClaims && (
                            <span>
                                <b>custom claims</b>:{" "}
                                {Object.entries(user.customClaims).map(
                                    ([key, value]) =>
                                        key +
                                        ": " +
                                        JSON.stringify(value) +
                                        ", ",
                                )}
                            </span>
                        )}
                        <span>
                            <b>Connected Organisations:</b>:{" "}
                            {userOrgs[user.uid] ?? "None"}
                        </span>
                    </UserDetails>
                    <Stack direction='row' spacing={1}>
                        <Button
                            variant='contained'
                            target='blank'
                            href={user.customerStripeLink}>
                            Stripe Customer Link
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => spoofUser(user.uid)}>
                            Spoof
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setDeleteUser(user);
                                setOpen(true);
                            }}>
                            Delete
                        </Button>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <>
            <Dialog onClose={() => setOpen(false)} open={open}>
                {deleteUser && (
                    <>
                        <Stack padding={5}>
                            <p>
                                <span>
                                    Do you wish to delete{" "}
                                    {deleteUser.displayName}.
                                </span>
                                <br />
                                <span>
                                    {" "}
                                    They are currently{" "}
                                    {deleteUser.subscriptionStatus}
                                </span>
                                <br />
                                <span>
                                    {" "}
                                    Last logged in at {deleteUser.signedInAt}
                                </span>
                            </p>
                        </Stack>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    delteUserMutation(deleteUser.uid);
                                    setDeleteUser(undefined);
                                    setOpen(false);
                                }}>
                                Delete {deleteUser.displayName}{" "}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Header />
            <Container>
                {isFetching && (
                    <div>
                        <h1>Loading...</h1>
                    </div>
                )}
                {isError && (
                    <div>
                        <h1>Error</h1>
                        <p>{JSON.stringify(error)}</p>
                    </div>
                )}
                {users && (
                    <>
                        <Stack
                            direction='row'
                            justifyContent='center'
                            spacing={2}
                            margin={1}>
                            <TextField
                                select
                                sx={{ minWidth: 150 }}
                                value={selectedSort}
                                label='sort'
                                onChange={e => setSelectedSort(e.target.value)}>
                                {SELECT_OPTIONS.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label='Search...'
                                variant='outlined'
                                onChange={e => setCurrentSearch(e.target.value)}
                            />
                        </Stack>
                        <PageData>
                            <TextField
                                select
                                label='filter'
                                value={selectedFilter}
                                sx={{
                                    maxHeight: 50,
                                    minWidth: 150,
                                }}
                                onChange={e =>
                                    setSelectedFilter(e.target.value)
                                }>
                                {FILTER_OPTIONS.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <List>
                                <FlatList
                                    renderOnScroll
                                    searchBy={["displayName", "email"]}
                                    searchTerm={currentSearch}
                                    searchCaseInsensitive
                                    sortBy={handleSort(selectedSort)}
                                    list={users ?? []}
                                    filterBy={filter}
                                    renderWhenEmpty={() => <div>No Items</div>}
                                    renderItem={(user, idx) => viewUser(user)}
                                />
                            </List>
                        </PageData>
                    </>
                )}
            </Container>
        </>
    );
};

export default Users;

const ModalDiv = styled("div")`
    background-color: white;
    width: 300px;
    height: 200px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    border: 2px solid black;
`;

const HeadBar = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
`;

const SelectSort = styled("select")``;

const SelectFilter = styled("div")`
    width: 150px;
`;

const List = styled("div")`
    display: flex;
    flex-direction: column;
`;

const PageData = styled("div")`
    display: flex;
    flex-direction: row;
`;

const Search = styled("input")`
    width: 300px;
    padding: 10px;
    border: 2px solid black;
    border-radius: 5px;
    font-size: 16px;
`;

const User = styled("div")`
    margin: 5px 0;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;

const UserHeaders = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    > span {
        margin-right: 10px;
    }
`;

const UserContent = styled("div")`
    margin: 10px;
    margin-top: 0px;
    border: 2px solid black;
    padding: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const UserDetails = styled("div")`
    display: flex;
    flex-direction: column;
`;

const UserControls = styled("div")`
    display: flex;
    flex-direction: column;
`;
