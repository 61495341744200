import { apiSlice } from "./api";

export interface EmailTemplates {
    name: string;
    subject: string;
    template: object;
    mergeFields: MergeField[];
    html: string;
}

export interface MergeField {
    id: string;
    name: string;
    value: string;
    sample: string;
}

const emailSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTemplates: builder.query<EmailTemplates[], void>({
            query: () => ({
                url: "/notification/email-templates",
            }),
        }),
        createTemplate: builder.mutation<void, EmailTemplates>({
            query: template => ({
                url: "/notification/email-templates",
                method: "POST",
                data: template,
            }),
            onQueryStarted: async (template, { dispatch, queryFulfilled }) => {
                const patch = dispatch(
                    emailSlice.util.updateQueryData(
                        "getTemplates",
                        undefined,
                        draft => {
                            draft.push(template);
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patch.undo();
                }
            },
        }),
        updateTemplate: builder.mutation<void, EmailTemplates>({
            query: template => ({
                url: "/notification/email-templates",
                method: "PUT",
                data: template,
            }),
            onQueryStarted: async (template, { dispatch, queryFulfilled }) => {
                const patch = dispatch(
                    emailSlice.util.updateQueryData(
                        "getTemplates",
                        undefined,
                        draft => {
                            const index = draft.findIndex(
                                template => template.name === template.name,
                            );
                            draft[index] = template;
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patch.undo();
                }
            },
        }),
        deleteTemplate: builder.mutation<void, string>({
            query: name => ({
                url: `/notification/email-templates`,
                method: "DELETE",
                params: {
                    name,
                },
            }),
            onQueryStarted: async (name, { dispatch, queryFulfilled }) => {
                const patch = dispatch(
                    emailSlice.util.updateQueryData(
                        "getTemplates",
                        undefined,
                        draft => {
                            const index = draft.findIndex(
                                template => template.name === name,
                            );
                            draft.splice(index, 1);
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patch.undo();
                }
            },
        }),
        testEmailTemplate: builder.mutation<
            void,
            {
                name: string;
                emails: string[];
                mergeFields: { [name: string]: string };
            }
        >({
            query: template => ({
                url: "/notification/email-templates/test",
                method: "POST",
                data: template,
            }),
        }),
    }),
});

export const {
    useGetTemplatesQuery,
    useCreateTemplateMutation,
    useUpdateTemplateMutation,
    useDeleteTemplateMutation,
    useTestEmailTemplateMutation,
} = emailSlice;
