import useAuthState from "hooks/firebase/useAuthState";
import useIsAdmin from "hooks/firebase/useIsAdmin";
import { useEffect, useState } from "react";
import { Navigate, PathRouteProps } from "react-router-dom";
import { auth } from "utils/firebase";

interface RequireAuthProps extends PathRouteProps {
    redirectTo?: string;
}

const PrivateRoute = ({ children, redirectTo, ...props }: RequireAuthProps) => {
    const [user, loading] = useAuthState();
    const [isAdmin, pending] = useIsAdmin();

    if (pending) return null;

    return user && isAdmin ? (
        <>{children}</>
    ) : (
        <Navigate
            to={redirectTo || "/login"}
            state={{ from: window.location.pathname }}
        />
    );
};

export default PrivateRoute;
