import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
};
const app = initializeApp(config);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, "australia-southeast1");

export const subbed: Array<(user: User | null) => void> = [];

onAuthStateChanged(auth, (user: User | null) => {
    subbed.forEach(cb => cb(user ? { ...user } : null));
});

export const subScribeToAuthStateChange = (
    callback: (user: User | null) => void,
) => {
    subbed.push(callback);
    return () => {
        subbed.indexOf(callback) > -1 &&
            subbed.splice(subbed.indexOf(callback), 1);
    };
};

export const updateUserState = () => {
    subbed.forEach(cb => cb(auth.currentUser ? auth.currentUser : null));
};

let token: undefined | string = undefined;

export const setToken = (newToken?: string) => {
    token = newToken;
};

export const getToken = () => {
    return token;
};
