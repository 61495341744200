import { Calculate } from "@mui/icons-material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CodeIcon from "@mui/icons-material/Code";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ImageIcon from "@mui/icons-material/Image";
import RedoIcon from "@mui/icons-material/Redo";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
    Alert,
    Container,
    Divider,
    IconButton,
    Input,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Color } from "@tiptap/extension-color";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { NodeSelection } from "@tiptap/pm/state";
import {
    ChainedCommands,
    Editor,
    EditorContent,
    Node,
    useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import awsImageFunctions from "helpers/awsImageFunctions";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
    useGetContentQuery,
    useUpdateEditableContentBlockMutation,
} from "redux/editableContentBlock";

import InsertFormulaDialog from "./insertFormula";
import InsertLinkDialog, { LinkTypeEnum } from "./insertLinkDlg";

const imgBaseUrlAws = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/img/`;

/**custom node for formulas */

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        formula: {
            /**
             * Toggle a formula
             * @example
             */
            setFormula: (options: {
                numberFormat: string;
                formula: string;
                abs?: boolean;
            }) => ReturnType;
        };
    }
}

const CustomFormulaNode = Node.create<{
    HTMLAttributes: Record<string, any>;
}>({
    name: "formula",
    content: "text*",
    group: "inline",
    inline: true,
    selectable: true,
    draggable: true,
    atom: true,
    addAttributes() {
        return {
            "data-formula": {
                default: null,
            },
            "data-number-format": {
                default: null,
            },
            "data-abs": {
                default: false,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: "span[data-formula][data-number-format]",
            },
        ];
    },
    renderHTML({ node }) {
        return [
            "span",
            {
                "data-formula": node.attrs["data-formula"],
                "data-number-format": node.attrs["data-number-format"],
                "data-abs": node.attrs["data-abs"] || false,
                style: "border: 1px solid red; padding: 2px;cursor: pointer;",
            },
            `${node.attrs["data-formula"]} : ${node.attrs["data-number-format"]}`,
        ];
    },
    addCommands() {
        return {
            setFormula:
                options =>
                ({ commands }) => {
                    return commands.insertContent({
                        type: this.name,
                        attrs: {
                            "data-formula": options.formula,
                            "data-number-format": options.numberFormat,
                            "data-abs": options.abs || false,
                        },
                    });
                },
        };
    },
});

const extensions = [
    CustomFormulaNode,
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    Image,
    Underline,
    TextStyle,
    TextAlign.configure({
        alignments: ["left", "center", "right", "justify"],
        types: ["heading", "paragraph"],
    }),
    Link.configure({
        protocols: ["ftp", "mailto"],
        linkOnPaste: false,
        autolink: false,
    }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false,
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false,
        },
        horizontalRule: {},
    }),
];

const getActiveClass = (editor: Editor, options: any) => {
    const isActive = editor.isActive(options);
    return isActive ? "active" : "";
};

const handleMenuClick = (
    editor: Editor,
    command: (c: ChainedCommands) => ChainedCommands,
) => {
    if (editor.state.selection.empty) {
        //If they have not selected any text, select the parent node
        //This behaviour is similar to how eitors like Google Docs works and so most users will expect it
        command(editor.chain().focus().selectParentNode()).run();
    } else {
        command(editor.chain().focus()).run();
    }
};

const MenuBar = ({
    editor,
    filePrefix,
    selectedNode,
}: {
    editor: Editor;
    filePrefix: string;
    selectedNode?: any;
}) => {
    const [showImgDlg, setShowImgDlg] = useState(false);
    const [showLinkDlg, setShowLinkDlg] = useState(false);
    const [showFormulaDlg, setShowFormulaDlg] = useState(false);

    useEffect(() => {
        if (selectedNode?.type?.name === "formula") {
            setShowFormulaDlg(true);
        }
    }, [selectedNode]);

    const [file, setFile] = useState<File>();

    const handleOpenImageDialog = () => {
        setShowImgDlg(true);
    };

    const handleOpenLinkDialog = () => {
        setShowLinkDlg(true);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleInsertImage = () => {
        if (file) {
            awsImageFunctions
                .uploadImageToAws(
                    file,
                    `${filePrefix}-${new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace(/[-:]/g, "")}`,
                )
                .then(fileName => {
                    if (fileName) {
                        editor
                            .chain()
                            .focus()
                            .setImage({ src: `${imgBaseUrlAws}${fileName}` })
                            .run();
                    }
                });
        }
        setFile(undefined);
        setShowImgDlg(false);
    };

    const handleCloseIngDlg = () => {
        setFile(undefined);
        setShowImgDlg(false);
    };

    const handleLinkInsert = (
        linkType: LinkTypeEnum,
        url: string,
        displayText: string | undefined,
    ) => {
        switch (linkType) {
            case LinkTypeEnum.WebpageNewWindow:
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        `<a href="${url}" target="_blank">${
                            displayText || url
                        }</a>`,
                    )
                    .run();
                break;
            case LinkTypeEnum.WebpageSameWindow:
                editor
                    .chain()
                    .focus()
                    .insertContent(`<a href="${url}">${displayText || url}</a>`)
                    .run();
                break;
            case LinkTypeEnum.Email:
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        `<a href="mailto:${url}">${displayText || url}</a>`,
                    )
                    .run();
                break;
        }
        setShowLinkDlg(false);
    };

    const handleFormulaInsert = (
        formula: string,
        numberFormat: string,
        abs?: boolean,
    ) => {
        editor.chain().focus().setFormula({ numberFormat, formula, abs }).run();
        setShowFormulaDlg(false);
    };

    return (
        <>
            <Dialog open={showImgDlg}>
                <DialogTitle>Insert Image</DialogTitle>
                <ul>
                    <li>
                        Resize your image in a photo editor first to suit your
                        need (they will display actual size on the website)
                    </li>
                    <li>
                        The maxamum file size is 1MB, try saving as a JPEG with
                        compression if your image is too large.
                    </li>
                </ul>
                <DialogContent>
                    <Input
                        type='file'
                        inputProps={{ accept: "image/*" }}
                        onChange={handleFileChange}
                    />
                </DialogContent>
                <Stack direction='row' spacing={1}>
                    <IconButton color='error' onClick={handleCloseIngDlg}>
                        <CancelIcon />
                        Cancel
                    </IconButton>
                    <IconButton
                        color='success'
                        onClick={handleInsertImage}
                        disabled={!file}>
                        <CheckCircleIcon />
                        Insert
                    </IconButton>
                </Stack>
            </Dialog>
            <InsertLinkDialog
                open={showLinkDlg}
                onCancel={() => setShowLinkDlg(false)}
                onInsert={handleLinkInsert}
            />
            <InsertFormulaDialog
                open={showFormulaDlg}
                initialValues={{
                    formula: selectedNode?.attrs?.["data-formula"] || "",
                    numberFormat:
                        selectedNode?.attrs?.["data-number-format"] ||
                        "currency",
                    abs: !!selectedNode?.attrs?.["data-abs"],
                }}
                onCancel={() => setShowFormulaDlg(false)}
                onInsert={({ formula, numberFormat, abs }) =>
                    handleFormulaInsert(formula, numberFormat, abs)
                }
            />
            <Stack direction='row' spacing={1} flexWrap='wrap'>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.setTextAlign("left"))
                    }
                    className={getActiveClass(editor, { textAlign: "left" })}>
                    <FormatAlignLeftIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.setTextAlign("center"))
                    }
                    className={getActiveClass(editor, { textAlign: "center" })}>
                    <FormatAlignCenterIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.setTextAlign("right"))
                    }
                    className={getActiveClass(editor, { textAlign: "right" })}>
                    <FormatAlignRightIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.setTextAlign("justify"))
                    }
                    className={getActiveClass(editor, {
                        textAlign: "justify",
                    })}>
                    <FormatJustifyIcon />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton
                    onClick={() => handleMenuClick(editor, c => c.toggleBold())}
                    className='active'>
                    <FormatBoldIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.toggleItalic())
                    }>
                    <FormatItalicIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.toggleUnderline())
                    }>
                    <FormatUnderlinedIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c =>
                            c.toggleHeading({ level: 1 }),
                        )
                    }>
                    <Typography variant='body1'>H1</Typography>
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c =>
                            c.toggleHeading({ level: 2 }),
                        )
                    }>
                    <Typography variant='body1'>H2</Typography>
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c =>
                            c.toggleHeading({ level: 3 }),
                        )
                    }>
                    <Typography variant='body1'>H3</Typography>
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c =>
                            c.toggleHeading({ level: 4 }),
                        )
                    }>
                    <Typography variant='body1'>H4</Typography>
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c =>
                            c.toggleHeading({ level: 5 }),
                        )
                    }>
                    <Typography variant='body1'>H5</Typography>
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c =>
                            c.unsetAllMarks().setParagraph(),
                        )
                    }>
                    <Typography variant='body1'>Paragraph</Typography>
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.toggleBulletList())
                    }>
                    <FormatListBulletedIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.toggleOrderedList())
                    }>
                    <FormatListNumberedIcon />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.toggleCodeBlock())
                    }>
                    <CodeIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.toggleBlockquote())
                    }>
                    <FormatQuoteIcon />
                </IconButton>
                <IconButton
                    onClick={() =>
                        handleMenuClick(editor, c => c.setHorizontalRule())
                    }>
                    <HorizontalRuleIcon />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton onClick={handleOpenImageDialog}>
                    <ImageIcon />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton
                    onClick={() => handleMenuClick(editor, c => c.undo())}>
                    <UndoIcon />
                </IconButton>
                <IconButton
                    onClick={() => handleMenuClick(editor, c => c.redo())}>
                    <RedoIcon />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton onClick={handleOpenLinkDialog}>
                    <AddLinkIcon />
                </IconButton>
                <Divider orientation='vertical' flexItem />
                <IconButton onClick={() => setShowFormulaDlg(true)}>
                    <Typography variant='body1'>
                        <Calculate />
                    </Typography>
                </IconButton>
                {/* <Divider orientation="vertical" flexItem />
            <SmartDateTagMenu /> */}
            </Stack>
        </>
    );
};

const EditableContentBlock = () => {
    const { contentId } = useParams<{ contentId: string }>();
    const [LinkText, setLinkText] = useState<string>();
    const [selectedNode, setSelectedNode] = useState<any>();

    if (!contentId) throw new Error("Missing content id");

    const contentResult = useGetContentQuery(contentId, { skip: !contentId });
    const [updateContent] = useUpdateEditableContentBlockMutation();

    const editor = useEditor({
        extensions,
        editorProps: {
            attributes: {
                class: "editor",
            },
        },
        onUpdate: ({ transaction }) => {
            //Compare images in the content before and after the transaction to keep our bucket clean
            const imgSrcs = new Set<string>();
            //First check the current content
            transaction.doc.forEach(node => {
                if (node.attrs.src?.startsWith(imgBaseUrlAws)) {
                    imgSrcs.add(node.attrs.src);
                }
            });
            //Now check the original content
            transaction.before.forEach(node => {
                if (
                    node.attrs.src?.startsWith(imgBaseUrlAws) &&
                    !imgSrcs.has(node.attrs.src)
                ) {
                    //This image is not in the updated content, remove it from the bucket
                    awsImageFunctions.deleteFileFromAws(
                        node.attrs.src.split("/").pop()!,
                    );
                }
            });
        },
        onSelectionUpdate: ({ editor }) => {
            if ("node" in editor.view.state.selection) {
                const node = (editor.view.state.selection as NodeSelection)
                    .node;
                setSelectedNode(node);
            } else {
                setSelectedNode(undefined);
            }
        },
    });

    useEffect(() => {
        if (editor && contentResult.isSuccess && editor.isEmpty) {
            const contentForEditor =
                contentResult.data?.content ||
                "<p>This section does not have any content yet</p><ul>Replace this text with your own content</ul><ul>Use the menu above to apply formatting and upload images.</ul><ul>Save your changes when you are finished.</ul>";

            //the images will be in the format <img src="[filename]" /> because we clean the src attribute when saving
            //we need to add the base url to display
            const contentWithBaseUrl = contentForEditor.replace(
                /<img src="([^"]+)"/g,
                `<img src="${imgBaseUrlAws}$1"`,
            );

            editor.commands.setContent(contentWithBaseUrl);
            setLinkText(contentResult.data?.title);
        }
    }, [editor, contentResult]);

    if (!contentId) return <p>Missing content id</p>;

    const handleSaveChanges = (): void => {
        if (editor) {
            const rawHtml = editor.getHTML();
            //remove style from any <span data-formula=
            let cleanHtml = rawHtml.replace(
                /<span data-formula="([^"]+)" data-number-format="([^"]+)"( data-abs="([^"]+)")?[^>]*>[^<]*<\/span>/g,
                '<span data-formula="$1" data-number-format="$2" data-abs="$4"></span>',
            );

            //preserve just the image file names
            cleanHtml = cleanHtml.replace(
                /src="https:\/\/[a-zA-Z0-9-]+\.s3\.amazonaws\.com\/img\/([^"]+)"/g,
                'src="$1"',
            );
            updateContent({
                contentId,
                content: cleanHtml,
                title: LinkText,
            }).then(response => {
                if (!("error" in response) || !response.error) {
                    exitEditor();
                } else {
                    alert(
                        "There was a problem saving your changes, please try again. The error is: " +
                            JSON.stringify(response.error),
                    );
                }
            });
        }
    };

    const handleDiscardChanges = () => {
        if (confirm("Close the editor and discard your changes?")) {
            //remove any images that were uploaded
            if (editor) {
                const deleteImages = async () => {
                    const imgTags = editor.getHTML().match(/<img[^>]+>/g);
                    if (imgTags) {
                        await Promise.all(
                            imgTags.map(async imgTag => {
                                const src = imgTag.match(/src="([^"]+)"/)?.[1];
                                if (src) {
                                    const fileName = src.split("/").pop();
                                    if (
                                        fileName &&
                                        !contentResult.data?.content.includes(
                                            fileName,
                                        )
                                    ) {
                                        await awsImageFunctions.deleteFileFromAws(
                                            fileName,
                                        );
                                    }
                                }
                            }),
                        );
                    }
                };

                deleteImages().then(() => {
                    exitEditor();
                });
            }
        }
    };

    return contentResult.isSuccess && editor ? (
        <Container maxWidth='md'>
            <Typography variant='h4'>Edit: {contentId}</Typography>
            <Stack
                direction='row'
                spacing={2}
                padding='10px 0'
                alignItems='center'>
                <Typography variant='body1' fontWeight='bold'>
                    Text for link:
                </Typography>
                <TextField
                    size='small'
                    placeholder='Tell me what this means'
                    value={LinkText}
                    onChange={e => setLinkText(e.target.value)}
                    sx={{ flexGrow: 1 }}
                />
            </Stack>
            <Alert severity='info'>
                Use the editor below to change the content that will be
                displayed on the app. Tips:
                <ul>
                    <li>
                        Use the menu above the editor window to apply formatting
                        and upload images.
                    </li>
                    <li>
                        If you have trouble with formatting, try removing all
                        formatting, break into the paragraphs you need, then
                        reapply formatting.
                    </li>
                    <li>
                        Use the formula button (looks like a calculator) to
                        insert a formula that can display the user's data.
                    </li>
                    <li>Click on an formula to edit it.</li>
                    <li>
                        If you type {`{{LATEST_MONTH}}`} the app will display
                        the month the reports are for, e.g. "June 2024"
                    </li>
                    <li>
                        Pay attention to spacing, expically before or after
                        items like formulas that are replaced with text (i.e. no
                        space if followed by a full stop, add a space if
                        followed by another word).
                    </li>
                    <li>
                        The text styles here are a representation, the app may
                        apply slightly different styles, but bold should still
                        be bold, etc.
                    </li>
                    <li>
                        Save your changes when you are finished and check the
                        result on the app.
                    </li>
                </ul>
            </Alert>
            <Stack direction='column' spacing={2}>
                <Divider orientation='horizontal' />
                <MenuBar
                    editor={editor}
                    filePrefix={contentId}
                    selectedNode={selectedNode}
                />
                <Divider orientation='horizontal' />
                <EditorContent editor={editor} />
                <Divider orientation='horizontal' />
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <IconButton onClick={handleSaveChanges} color='success'>
                    <SaveIcon />
                    Save and close
                </IconButton>
                <IconButton onClick={handleDiscardChanges} color='error'>
                    <DeleteForeverIcon />
                    Abandon Changes
                </IconButton>
            </Stack>
        </Container>
    ) : null;
};

export default EditableContentBlock;

function exitEditor() {
    try {
        //Works in most browsers
        window.open("about:blank", "_self");
        window.close();
    } catch (e) {}
    //This editor is desigend to be saved on completion, as each edit is versioned in the db
    //Unless we can figure out a way to close the window automatically, we will just show a message
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    overlay.style.zIndex = "1000";
    //Add text to the center of the page
    const text = document.createElement("p");
    text.style.position = "absolute";
    text.style.top = "50%";
    text.style.left = "50%";
    text.style.transform = "translate(-50%, -50%)";
    text.style.color = "white";
    text.style.fontSize = "2rem";
    text.style.fontWeight = "bold";
    text.textContent = "Please close this window to return to the app.";
    overlay.appendChild(text);
    document.body.appendChild(overlay);
}
