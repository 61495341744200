import styled from "@emotion/styled";
import { Container } from "@mui/material";
import LoginAuth from "components/auth/LoginAuth";

const Login = () => {
    return (
        <Container>
            <LoginAuth />
        </Container>
    );
};

export default Login;
