import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    styled,
} from "@mui/material";
import Footer from "components/page_parts/Footer";
import Header from "components/page_parts/Header";
import FlatList, { FlatListProps } from "flatlist-react/lib";
import OutsideAlerter from "helpers/OutsideAlerter";
import { useState } from "react";
import { useGetLogsQuery } from "redux/api";
import { Log, Severity } from "types/Logs";

enum FilterOptions {
    all = "all",
    info = "info",
}

const FILTER_OPTIONS: FilterOptions[] = [FilterOptions.all, FilterOptions.info];

const Logs = () => {
    const { data: logs } = useGetLogsQuery(50);
    const [selectedFilter, setSelectedFilter] = useState<FilterOptions>(
        FilterOptions.all,
    );
    const [open, setOpen] = useState("");
    const handleSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as FilterOptions;
        setSelectedFilter(value);
    };

    const renderLog = (log: Log) => {
        return (
            <Accordion key={log.insertId}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    {log.timestamp.toLocaleString()} {log.severity}{" "}
                    {log.jsonPayload.fields.message.stringValue}
                </AccordionSummary>
                <AccordionDetails>
                    <pre>
                        {JSON.stringify(
                            log.jsonPayload.fields.metadata,
                            null,
                            2,
                        )}
                    </pre>
                </AccordionDetails>
            </Accordion>
        );
    };

    const filter: FlatListProps<Log>["filterBy"] = (() => {
        switch (selectedFilter) {
            case FilterOptions.all:
                return undefined;
                break;
            case FilterOptions.info:
                return (log: Log) => log.severity === Severity.INFO;

            default:
                break;
        }
    })();

    return (
        <>
            <Header />
            <PageData>
                <SelectFilter>
                    <select onChange={handleSelectFilter}>
                        {FILTER_OPTIONS.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </SelectFilter>
                <List>
                    <FlatList
                        renderOnScroll
                        filterBy={filter}
                        renderItem={renderLog}
                        list={logs || []}
                    />
                </List>
            </PageData>
            {/* <FlatList
                                renderOnScroll
                                searchBy={["displayName", "email"]}
                                searchTerm={currentSearch}
                                sortBy={selectedSort}
                                list={users ?? []}
                                filterBy={filter}
                                renderWhenEmpty={() => <div>No Items</div>}
                                renderItem={(user, idx) => viewUser(user)}
                            /> */}
            <Footer />
        </>
    );
};

export default Logs;

const LogContainer = styled("div")`
    padding: 10px;
    border-bottom: 1px solid ${props => props.theme.palette.secondary.main};
    background-color: #fff;
    cursor: pointer;
    pre {
        background-color: ${props => props.theme.palette.secondary.main};
        color: #fff;
        padding: 10px;
        margin: 0;
        cursor: default;
    }
`;

const List = styled("div")`
    display: flex;
    flex-direction: column;
`;

const PageData = styled("div")`
    display: flex;
    flex-direction: row;
`;

const SelectFilter = styled("div")`
    width: 150px;
`;
