import {
    Button,
    Chip,
    Container,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Header from "components/page_parts/Header";
import { useRef, useState } from "react";
import {
    EmailTemplates,
    useGetTemplatesQuery,
    useTestEmailTemplateMutation,
} from "redux/email";

const EmailSender = () => {
    const { data: templates } = useGetTemplatesQuery();
    const [sendTestEmail] = useTestEmailTemplateMutation();
    const [numberOfDestinations, setNumberOfDestinations] = useState([0]);
    const [input, setInput] = useState<{ [key: string]: string | null }>({});
    const [template, setTemplate] = useState<EmailTemplates | undefined>(
        undefined,
    );

    const handleAutoFill = () => {
        if (!template) return;
        const mergeFields = template.mergeFields.reduce((output, field) => {
            output[field.name] = field.sample;
            return output;
        }, {} as { [key: string]: string });
        setInput(prev => ({ ...prev, ...mergeFields }));
    };

    console.log(template);

    return (
        <>
            <Header />
            <Container>
                <h1>Email Sender</h1>
                <Stack alignItems='center'>
                    <Typography variant='h6'>Templates</Typography>
                    <Stack flexWrap='wrap' margin={2} spacing={1}>
                        {templates?.map((template, index) => (
                            <Chip
                                sx={{ minWidth: 100 }}
                                key={index}
                                label={template.name}
                                onClick={() => setTemplate(template)}
                            />
                        ))}
                    </Stack>
                </Stack>
                {template && (
                    <form
                        onSubmit={event => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(
                                (formData as any).entries(),
                            );

                            const destinations = Object.entries(formJson)
                                .filter(p => p[0].includes("destination"))
                                .map(p => p[1]);

                            sendTestEmail({
                                name: template.name,
                                emails: destinations,
                                mergeFields: template.mergeFields.reduce(
                                    (output, field) => {
                                        output[field.id] =
                                            formJson[field.name];
                                        return output;
                                    },
                                    {} as { [key: string]: string },
                                ),
                            });
                        }}>
                        <Stack alignItems='center'>
                            <Typography variant='h6'>
                                Template Name: {template.name}
                            </Typography>
                            <Stack spacing={2}>
                                {numberOfDestinations.map((_, index) => (
                                    <TextField
                                        key={index}
                                        id={`destination ${index + 1}`}
                                        label={`Destination ${index + 1}`}
                                        name={`destination ${index + 1}`}
                                        type='email'
                                        placeholder='example@example.com'
                                        required
                                        variant='outlined'
                                    />
                                ))}
                                <Stack spacing={2} direction='row'>
                                    <Button
                                        variant='contained'
                                        onClick={() =>
                                            setNumberOfDestinations([
                                                ...numberOfDestinations,
                                                numberOfDestinations.length,
                                            ])
                                        }>
                                        Add Destination
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            numberOfDestinations.length > 1 &&
                                                setNumberOfDestinations(
                                                    numberOfDestinations.slice(
                                                        0,
                                                        numberOfDestinations.length -
                                                            1,
                                                    ),
                                                );
                                        }}>
                                        Remove Destination
                                    </Button>
                                </Stack>
                            </Stack>

                            <Stack
                                spacing={2}
                                minWidth={400}
                                marginTop={2}
                                marginBottom={2}>
                                <Stack>
                                    <Typography variant='h6'>
                                        Merge Fields
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        onClick={handleAutoFill}>
                                        Auto Fill with Sample Data
                                    </Button>
                                </Stack>
                                {template.mergeFields.map((field, index) => (
                                    <TextField
                                        value={input[field.name] || ""}
                                        id={field.name}
                                        label={field.name}
                                        name={field.name}
                                        placeholder={field.sample}
                                        key={index}
                                        required
                                        variant='outlined'
                                    />
                                ))}
                            </Stack>
                            <Button variant='contained' type='submit'>
                                Send
                            </Button>
                        </Stack>
                    </form>
                )}
            </Container>
        </>
    );
};

export default EmailSender;
