import { MenuItem, Select, Stack, styled, Typography } from "@mui/material";
import Header from "components/page_parts/Header";
import { useState } from "react";
import { useAppSelector } from "redux/store";
import { useGetUsersQuery, useGetUserTokenQuery } from "redux/user";

const Spoof = () => {
    const uid = useAppSelector(state => state.userSlice.uid);
    const {
        data: token,
        isFetching,
        isError,
        error,
    } = useGetUserTokenQuery(uid);

    const { data: users } = useGetUsersQuery();

    const user = users?.find(user => user.uid === uid);

    const [target, setTarget] = useState<string>(
        process.env.REACT_APP_SPOOF_ADDRESS || "https://app.hellocashflow.com",
    );

    const options = [
        "https://app.hellocashflow.com",
        "https://preview.hellocashflow.com",
        "http://localhost:3000",
        "https://old.hellocashflow.com",
    ];

    return (
        <>
            <Header />
            {/* <iframe src="https://app.hellocashflow.com" /> */}
            {isFetching && <div>loading...</div>}
            {isError && <div>{JSON.stringify(error)}</div>}
            {token && (
                <Stack padding={5} spacing={2}>
                    <Typography variant='h4'>
                        Click the button below to login as the selected user:
                    </Typography>
                    <Typography>
                        {user?.displayName} - {user?.email}
                    </Typography>
                    <Typography variant='h5'>Target website:</Typography>
                    <Select
                        value={target}
                        onChange={e => setTarget(e.target.value)}>
                        {options.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography variant='h6'>
                        Right-click the link below and select "Open link in
                        incognito/private window":
                    </Typography>
                    <Typography fontStyle='italic'>
                        Opening in incognito/private mode will allow you to
                        login as the user without affecting your current
                        session.
                    </Typography>
                    <a
                        href={`${target}/login?token=${token}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {target}
                    </a>
                </Stack>
            )}
            {/* {token && (
                <IframeContainer>
                    <iframe
                        src={`${process.env.REACT_APP_SPOOF_ADDRESS || "https://app.hellocashflow.com"}/login?token=${token}`}
                    />
                </IframeContainer>
            )} */}
        </>
    );
};

export default Spoof;

const IframeContainer = styled("div")`
    height: 900px;
    width: 100%;

    iframe {
        height: 100%;
        width: 100%;
    }
`;
