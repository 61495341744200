import {
    GoogleAuthProvider,
    signInWithPopup,
    UserCredential,
} from "firebase/auth";
import { NavigateFunction } from "react-router";
import { auth } from "utils/firebase";

export const signInWithGoogle = (navigate: NavigateFunction, to: string) => {
    signInWithPopup(auth, new GoogleAuthProvider())
        .then(result => {
            handleSignIn(result, to, navigate);
        })
        .catch(error => {
            alert(`failed to sign in with google: ${error.message}`);
        });
};

export const handleSignIn = async (
    result: UserCredential,
    to: string,
    navigate: NavigateFunction,
) => {
    const user = result.user;
    const claims = await user.getIdTokenResult();
    
    if (claims.claims.admin) {
        navigate(to);
    }
};
