export type Logs = Log[];

export interface Log {
    timestamp: Date;
    labels: Labels;
    insertId: string;
    httpRequest: any;
    resource: Resource;
    severity: Severity;
    logName: string;
    operation: any;
    trace: string;
    sourceLocation: any;
    receiveTimestamp: ReceiveTimestamp;
    spanId: string;
    traceSampled: boolean;
    split: any;
    jsonPayload: JsonPayload;
    payload: string;
}

export enum Severity {
    DEBUG = "DEBUG",
    INFO = "INFO",
    NOTICE = "NOTICE",
    WARNING = "WARNING",
    ERROR = "ERROR",
    CRITICAL = "CRITICAL",
    ALERT = "ALERT",
    EMERGENCY = "EMERGENCY",
  }

export interface Labels {
    service: string;
}

export interface Resource {
    labels: Labels2;
    type: string;
}

export interface Labels2 {
    project_id: string;
}

export interface ReceiveTimestamp {
    seconds: string;
    nanos: number;
}

export interface JsonPayload {
    fields: Fields;
}

export interface Fields {
    message: Message;
    metadata: Metadata;
}

export interface Message {
    stringValue: string;
    kind: string;
}

export interface Metadata {
    structValue: StructValue;
    kind: string;
}

export interface StructValue {
    fields: Fields2;
}

export interface Fields2 {}
