import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Container,
    Dialog,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import Header from "components/page_parts/Header";
import { format, parseISO } from "date-fns";
import { OrgSubscriptionStatus } from "HelloCashflowDomain/DomainTypes/db/OrganisationOptions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    useGetOrganisationsQuery,
    useGetRefreshedTokenQuery,
    useLazySyncDataQuery,
} from "redux/organisation";
import { useAppDispatch } from "redux/store";
import { setUID } from "redux/userSlice";

const OrganisationList = () => {
    const navaigate = useNavigate();
    const dispatch = useAppDispatch();
    const [syncData, syncDataStatus] = useLazySyncDataQuery();
    const { data: organisations, isLoading } = useGetOrganisationsQuery();
    const [openOrgId, setOpenOrgId] = useState<string>();
    const [devOrgId, setDevOrgId] = useState<string>();
    const [tokenOrg, setTokenOrg] = useState<string>();

    const [sort, setSort] = useState<"name" | "last active">("name");

    const refreshedToken = useGetRefreshedTokenQuery(
        tokenOrg || skipToken,
    )?.data;

    const [filter, setFilter] = useState<string>("");

    const [exactMatch, setExactMatch] = useState<boolean>(false);
    const [subscribersOnly, setSubscribersOnly] = useState<boolean>(false);

    const spoofUser = (uid: string) => {
        dispatch(setUID(uid));
        navaigate("/spoof");
    };

    const [useOrgs, setUseOrgs] = useState(organisations);

    useEffect(() => {
        if (!organisations) return;
        let orgs = [...organisations];
        if (filter) {
            const orgNames = filter
                .split(/,|\n/)
                .map(name => name.trim().toLowerCase());

            orgs = organisations?.filter(org =>
                exactMatch
                    ? orgNames.some(name => org.name?.toLowerCase() === name)
                    : orgNames.some(name =>
                          org.name?.toLowerCase().includes(name),
                      ),
            );
        }
        orgs.sort((a, b) => {
            switch (sort) {
                case "name":
                    return a.name.localeCompare(b.name);
                case "last active":
                    return (
                        (b.itemsUpdated
                            ? new Date(b.itemsUpdated).getTime()
                            : 0) -
                        (a.itemsUpdated
                            ? new Date(a.itemsUpdated).getTime()
                            : 0)
                    );
            }
        });
        if (subscribersOnly) {
            orgs = orgs.filter(
                org => org.subscriptionStatus !== OrgSubscriptionStatus.none,
            );
        }
        setUseOrgs(orgs);
    }, [filter, organisations, exactMatch, subscribersOnly, sort]);

    const syncOrgData = (orgId: string) => {
        setDevOrgId(undefined);
        syncData({ organisationId: orgId }).then(r => {
            alert(JSON.stringify(r));
        });
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <>
            <Dialog open={!!devOrgId} onClose={() => setDevOrgId("")}>
                <DialogContent>
                    <Typography variant='h2'>Developer Tools</Typography>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setTokenOrg(devOrgId);
                        }}>
                        Refresh token
                    </Button>

                    <Button
                        variant='contained'
                        onClick={() => {
                            devOrgId && syncOrgData(devOrgId);
                        }}>
                        Sync procider data (full three years)
                    </Button>

                    {refreshedToken && tokenOrg === devOrgId && (
                        <>
                            {!refreshedToken ? (
                                <CircularProgress />
                            ) : (
                                <div>
                                    <h4>
                                        Here are the refreshed token details
                                    </h4>
                                    <Typography>
                                        API Token: {refreshedToken.apiToken}
                                    </Typography>
                                    <Typography>
                                        Refresh Token:{" "}
                                        {refreshedToken.refreshToken}
                                    </Typography>
                                    <Typography>
                                        Expires At: {refreshedToken.expiresAt}
                                    </Typography>
                                    <Typography>
                                        ID Token: {refreshedToken.idToken}
                                    </Typography>
                                    <h4>
                                        Here is the PgSql query to update the
                                        token in the test database
                                    </h4>
                                    <pre>
                                        {`UPDATE public."ProviderConnection"
    SET "apiToken" = '${refreshedToken.apiToken}', 
        "refreshToken" = '${refreshedToken.refreshToken}',
        "expiresAt" = '${refreshedToken.expiresAt}',
        "idToken" = '${refreshedToken.idToken}'
    WHERE "id" = '${refreshedToken.id}';`}
                                    </pre>
                                </div>
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <Header />
            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                <h1>Filter</h1>
                <Typography>
                    Enter 1 or more organisation names, seperated by commas or
                    line breaks to filter the list of organisations.
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    label='Organisation Names'
                    placeholder='Organisation Names'
                    sx={{ marginTop: 2 }}
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                />
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography fontWeight='bold'>Filter Options:</Typography>
                    <Checkbox
                        checked={exactMatch}
                        onChange={e => setExactMatch(e.target.checked)}
                        sx={{ marginTop: 2 }}
                    />
                    <Typography paddingRight={10}>Exact Match</Typography>
                    <Checkbox
                        checked={subscribersOnly}
                        onChange={e => setSubscribersOnly(e.target.checked)}
                        sx={{ marginTop: 2 }}
                    />
                    <Typography paddingRight={10}>
                        Show only organisations with current subscriptions
                    </Typography>
                    <Button
                        variant='contained'
                        onClick={() => setFilter("")}
                        sx={{ marginTop: 2 }}>
                        Clear
                    </Button>

                    <Box paddingLeft={10}>
                        Sort by:{" "}
                        <Select
                            value={sort}
                            onChange={e => setSort(e.target.value as any)}>
                            <MenuItem value='last active'>Last Active</MenuItem>
                            <MenuItem value='name'>Name</MenuItem>
                        </Select>
                    </Box>
                </Stack>
            </Paper>
            <Container>
                {filter && useOrgs && organisations && (
                    <h2>
                        Showing {useOrgs.length} of {organisations.length}{" "}
                        organisations
                    </h2>
                )}
                <List>
                    {useOrgs?.map((org, i) => (
                        <div key={org.id}>
                            <ListItem>
                                <ListItemText
                                    primary={org.name}
                                    secondary={`Subscription Status: ${
                                        OrgSubscriptionStatus[
                                            org.subscriptionStatus
                                        ]
                                    }`}
                                    sx={{
                                        fontWeight: "bold",
                                        color: "text.primary",
                                    }}
                                />
                                <Typography paddingRight={5}>
                                    Last active:{" "}
                                    {org.itemsUpdated
                                        ? format(
                                              parseISO(org.itemsUpdated),
                                              "dd MMMM yyyy",
                                          )
                                        : "Invalid date: " + org.itemsUpdated}
                                </Typography>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() =>
                                        setOpenOrgId(
                                            org.id === openOrgId
                                                ? undefined
                                                : org.id,
                                        )
                                    }>
                                    {org.id === openOrgId
                                        ? "Hide Users"
                                        : "Show Users"}
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        setTokenOrg(undefined);
                                        setDevOrgId(org.id);
                                    }}
                                    sx={{
                                        marginLeft: "10px",
                                    }}>
                                    Dev tools
                                </Button>
                            </ListItem>
                            <Collapse
                                in={org.id === openOrgId}
                                sx={{
                                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                                    marginLeft: 10,
                                }}>
                                <List component='div'>
                                    {org.users.map(user => (
                                        <ListItem key={user.id}>
                                            <Button
                                                variant='contained'
                                                onClick={() =>
                                                    spoofUser(user.firebaseId)
                                                }
                                                sx={{
                                                    marginRight: "10px",
                                                }}>
                                                Spoof User
                                            </Button>
                                            <ListItemText
                                                primary={user.name || "No Name"}
                                                secondary={
                                                    user.email || "No Email"
                                                }
                                            />
                                            <Stack direction='row'>
                                                <Typography fontWeight='bold'>
                                                    Firebase:
                                                </Typography>
                                                <Typography>
                                                    Id:{user.firebaseUser?.uid}
                                                </Typography>
                                                <Typography>
                                                    Email:
                                                    {user.firebaseUser?.email}
                                                </Typography>
                                                <Typography>
                                                    Name:
                                                    {
                                                        user.firebaseUser
                                                            ?.displayName
                                                    }
                                                </Typography>
                                            </Stack>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </div>
                    ))}
                </List>
            </Container>
        </>
    );
};

export default OrganisationList;
