import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { ColorModeContext } from "App";
import { getAuth } from "firebase/auth";
import useAuthState from "hooks/firebase/useAuthState";
import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

const pages = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "Users",
        path: "/users",
    },
    {
        name: "Organisations",
        path: "/organisations",
    },
    {
        name: "Spoof",
        path: "/spoof",
    },
    {
        name: "Logs",
        path: "/logs",
    },
    {
        name: "Emails",
        path: "/emails",
    },
    {
        name: "Email Sender",
        path: "/email-sender",
    },
];

const Header = () => {
    const theme = useTheme();
    const [user] = useAuthState();
    const colorMode = useContext(ColorModeContext);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar color='primary' position='static'>
            <Container>
                <Stack>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}>
                            {pages.map(page => (
                                <Button
                                    sx={{
                                        mr: 2,
                                        // color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.dark,
                                    }}
                                    key={page.path}
                                    component={RouterLink}
                                    // variant='contained'
                                    to={page.path}>
                                    {page.name}
                                </Button>
                            ))}
                        </Box>

                        <IconButton
                            sx={{ ml: 1 }}
                            onClick={colorMode.toggleColorMode}
                            color='inherit'>
                            {theme.palette.mode === "dark" ? (
                                <Brightness7Icon />
                            ) : (
                                <Brightness4Icon />
                            )}
                        </IconButton>
                        <Box sx={{ flexGrow: 0, marginLeft: 2 }}>
                            <Tooltip title='Open settings'>
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}>
                                    <Avatar
                                        alt={user?.displayName || undefined}
                                        src={user?.photoURL || " "}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id='menu-appbar'
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}>
                                <MenuItem
                                    onClick={() => {
                                        handleCloseUserMenu();
                                        getAuth().signOut();
                                    }}>
                                    <Typography textAlign='center'>
                                        Sign out
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Stack>
            </Container>
        </AppBar>
    );
};

export default Header;
