import { getAuth, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { subScribeToAuthStateChange } from "utils/firebase";

export type LoadingHook<T, E> = [T | undefined, boolean, E | undefined];
export type AuthStateHook = LoadingHook<User | null, Error>;

const auth = getAuth();

let global_loading = true;

const useAuthState = () => {
    const [userState, setUser] = useState<User | null>(auth.currentUser);
    const [loading, setLoading] = useState(global_loading);

    useEffect(() => {
        return subScribeToAuthStateChange((user: User | null) => {
            setUser(user);
            setLoading(false);
            global_loading = false;
        });
    }, []);

    return [userState, loading] as const;
};

export default useAuthState;
