import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Alert,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataTagType } from "HelloCashflowDomain";
import React, { useEffect, useRef } from "react";

interface FormulaDialogProps {
    open: boolean;
    onCancel: () => void;
    onInsert: (props: {
        formula: string;
        numberFormat: NumberFormatType;
        abs: boolean;
    }) => void;
    initialValues?: {
        formula: string;
        numberFormat: NumberFormatType;
        abs: boolean;
    };
}

export type NumberFormatType = "currency" | "percent" | "text";

const InsertFormulaDialog: React.FC<FormulaDialogProps> = ({
    open,
    onInsert,
    onCancel,
    initialValues,
}) => {
    const [numberFormat, setNumberFormat] =
        React.useState<NumberFormatType>("currency");
    const [formulaText, setFormulaText] = React.useState("");
    const [abs, setAbs] = React.useState(false);

    useEffect(() => {
        if (initialValues) {
            setNumberFormat(initialValues.numberFormat);
            setFormulaText(initialValues.formula);
            setAbs(initialValues.abs);
        }
    }, [initialValues]);

    //Error states
    const [error, setError] = React.useState({
        error: false,
        message: "",
    });

    const handleInsert = () => {
        const error = validateFormula(formulaText);
        if (error) {
            setError(error);
            return;
        } else {
            setError({ error: false, message: "" });
            onInsert({
                formula: formulaText,
                numberFormat,
                abs,
            });
            setFormulaText("");
        }
    };

    const inputBoxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputBoxRef.current?.focus();
    }, [formulaText]);

    return (
        <Dialog open={open} maxWidth='md' fullWidth>
            <DialogTitle>Insert formula</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    Note: Use formulas sparingly. Generally it is better to let
                    the app provide the data to the user. However this feature
                    is provided for flexibility so you can add context to the
                    educational content by quoting actual values.
                    <ul>
                        <li>
                            Be sure to check the result on the app against the
                            known values (e.g. from a chart, table, or figure
                            elsewhere on the app)
                        </li>
                        <li>
                            The more complex a formula, the more likely
                            something is to go wrong. Keep it simple, if
                            possible.
                        </li>
                        <li>
                            Conditional text must use TWO equal signs (==) for
                            comparison, not one (=)
                        </li>
                    </ul>
                </Alert>
                <Typography variant='body1'>
                    You may use any common mathmatical operator (+, -, *, /).
                    Use the dropdown list to insert fields.
                </Typography>
                <Typography variant='body1'>
                    Examples: <br />
                    <ul>
                        <li>
                            [REVENUE_LATEST_MONTH] / [REVENUE_PREVIOUS_MONTH] -
                            1 (gives the percentage change in revenue from the
                            previous month)
                        </li>
                        <li>
                            [REVENUE_LATEST_MONTH] - [REVENUE_PREVIOUS_MONTH]
                            (gives the difference in revenue from the previous
                            month)
                        </li>
                        <li>
                            Conditional text can be added by using the ternary
                            format, examples:
                            <br />
                            <strong>
                                [REVENUE_LATEST_MONTH] {`>`} 0 ? "Revenue is
                                positive" : "Revenue is negative"
                            </strong>
                            <br />
                            This will show the message 'Revenue is positive' if
                            the revenue is greater than 0, otherwise it will
                            show "Revenue is negative"
                            <br />
                            <strong>
                                [REVENUE_LATEST_MONTH] == 0 ? "You had no
                                revenue this month, ensure you are up to date
                                with reconciling" : ""
                            </strong>
                            <br />
                            This will only show a message if the revenue is 0,
                            the empty set of quotes at the end is still
                            required.
                        </li>
                    </ul>
                </Typography>
                <Stack direction='column' spacing={1}>
                    <Typography variant='body1' fontWeight='bold'>
                        Availible fields (select an item from this list to
                        insert in formula box):
                    </Typography>
                    <Select
                        value=''
                        onChange={e => {
                            setFormulaText(
                                formulaText +
                                    (formulaText.length ? " " : "") +
                                    `[${e.target.value}] `,
                            );
                            e.target.value = "";
                        }}>
                        {Object.keys(DataTagType)
                            .filter(key => isNaN(Number(key)))
                            .map(key => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            ))}
                    </Select>
                    <Typography variant='body1' fontWeight='bold'>
                        Formula:
                    </Typography>
                    <TextField
                        inputRef={inputBoxRef}
                        value={formulaText}
                        onChange={e => setFormulaText(e.target.value)}
                        label='Enter formula'
                        fullWidth
                        onKeyDown={e => {
                            //If the key pressed is "/" then set number format to percent
                            if (e.key === "/") {
                                setNumberFormat("percent");
                            }
                            //if the key is backspace or delete, and the formula contains no "/" then set number format to currency
                            if (
                                (e.key === "Backspace" || e.key === "Delete") &&
                                !formulaText.includes("/")
                            ) {
                                setNumberFormat("currency");
                            }
                            if (e.key === "?") {
                                setNumberFormat("text");
                            }
                        }}
                    />
                    <InputLabel id='link-type'>Format result as:</InputLabel>
                    <Select
                        labelId='link-type'
                        value={numberFormat}
                        onChange={e =>
                            setNumberFormat(e.target.value as NumberFormatType)
                        }
                        fullWidth>
                        <MenuItem value='currency'>Currency</MenuItem>
                        <MenuItem value='percent'>Percent</MenuItem>
                        <MenuItem value='text'>Text</MenuItem>
                    </Select>
                    {error.error && (
                        <Typography color='error' variant='body1'>
                            {error.message}
                        </Typography>
                    )}
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography variant='body1'>Absolute value:</Typography>
                        <Checkbox
                            checked={abs}
                            onChange={e => setAbs(e.target.checked)}
                        />
                        <Typography variant='body1' fontStyle='italic'>
                            (If checked, the result will be an absolute value,
                            that is without a plus or minus, make sure to adust
                            your wording accordingly)
                        </Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <Stack direction='row' spacing={1}>
                <IconButton color='error' onClick={onCancel}>
                    <CancelIcon />
                    Cancel
                </IconButton>
                <IconButton color='success' onClick={handleInsert}>
                    <CheckCircleIcon />
                    Insert
                </IconButton>
            </Stack>
        </Dialog>
    );
};

export default InsertFormulaDialog;

export const validateFormula = (formula: string) => {
    //Check if all square brackets have valid keys
    const keys = Object.keys(DataTagType).filter(key => isNaN(Number(key)));
    const keyRegex = /\[([^\]]+)\]/g;
    const matches = formula.match(keyRegex);
    if (matches && matches.length) {
        for (const match of matches) {
            const key = match.slice(1, -1);
            if (!keys.includes(key)) {
                return {
                    error: true,
                    message: `Invalid key: ${key}`,
                };
            }
        }
    }

    //Replace everthing in square brackets with a placeholder number 1, then see if the formula is valid
    const formulaWithNumbers = formula.replace(/\[[^\]]+\]/g, "1");
    try {
        // eslint-disable-next-line no-eval
        eval(formulaWithNumbers);
    } catch (error) {
        return {
            error: true,
            message: "Invalid formula",
        };
    }
};
